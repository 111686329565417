.notification-card {
    /* Frame 146 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 16px;
    gap: 10px;

    background: #ececece5;
    mix-blend-mode: luminosity;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 85px;
    margin-bottom: 32px;
    font-weight: 500;
    max-width: 100%;
    margin-right: 3em;
    margin-left: 3em;
}

.notification-card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.1px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.notification-card-body {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    letter-spacing: 0.1px;
    margin-top: 8px;
}

.notification-card-dismiss {
    /* Frame 42 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 10px;

    width: 34px;
    height: 34px;

    background: #fbfbfb;
    color: black;
    border-radius: 27px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.notification-card-dismiss:hover {
    background: #e0e0e0;
}
.notification-card-dismiss:active {
    background: #d0d0d0;
}

.notification-card-header {
    display: flex;
    flex-direction: row;
    vertical-align: bottom;
}

.unread-notification {
    font-weight: bolder;
}

.notification-action {
    padding: 0px;

    margin: 0 auto;
    height: 40px;

    /* Grays/White */
    background: #ffffff !important;
    border-radius: 100px !important;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* M3/ref/neutral/neutral17 */
    color: #2b2930 !important;
}
