/* App.css */

body {
    background: #fff;
}

.sidebar {
    width: fit-content;
    height: 100%;
    background-color: #e5e5ea; /* Light grey for the sidebar */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0px;
    z-index: 15;
}

.sidebar-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.sidebar-item {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.sidebar-link {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #333; /* Dark color for text */
    font-size: 12px;
    font-weight: 500;
}

.sidebar-link:hover {
    color: #000;
    cursor: pointer;
    font-weight: 700;
}

.sidebar-item .sidebar-link {
    border-left: 3px solid transparent;
    transition: all 0.3s;
}

.sidebar-item .sidebar-icon {
    padding: 5px 10px;
}

.sidebar-item .sidebar-icon:hover {
    border-radius: 100px;
    background-color: #d1d1d6;
}

.cities-tabs-nav {
    margin-bottom: 1px;
    background-color: #f2f2f7;
    position: sticky;
    top: 0;
    z-index: 10;
    padding-left: 0px;
}

.notification-badge .MuiBadge-badge {
    background-color: white;
    color: black;
}
