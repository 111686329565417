.watermark {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 0, 0, 0.5);
    transform: rotate(-45deg);
    pointer-events: none;
    z-index: 1000;
  }
  