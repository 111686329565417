.job-details-container {
    padding: 16px;
    background-color: #f9f9f9;
  }

  .job-tabs {
    display: flex;
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }

  .tab-item {
    padding: 15px 16px;
    cursor: pointer;
    background: none;
    border: none;
  }

  .tab-item.active {
    border-bottom: 3px solid black;
    font-weight: bold;
  }

  .job-items {
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }

  .job-item-row {
    display: flex;
    gap: 8px;
    padding: 8px;
    flex: 1;
  }

  .job-item-field {
    flex: 1;
    min-width: 80px;
    padding: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
  }

  input#outlined-helperText {
    -webkit-text-fill-color: black;
}

  .job-notes {
    padding: 8px;
  }

  .job-note {
    font-weight: bold;
    margin-top: 8px;
  }
