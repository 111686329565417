/* Job List Section */
span.dt-column-title {
    color: gray;
}

#DataTables_Table_0 td {
    margin-bottom: 25px;
    border: none;
    padding: 15px;
}

#DataTables_Table_0 th {
    margin-bottom: 45px;
    border: none;
    padding-bottom: 25px;
}

#DataTables_Table_0_wrapper tr {
    border: none;
}

.active-city {
    font-weight: 700;
    color: #000;
    border-bottom: 3px solid #000;
    border-radius: 0;
}

.highlight-description {
    background-color: #f0f0f0;
    font-weight: bold;
    font-style: italic;
}

/* Job Detail Section */
.detail-view {
    position: fixed;
    top: 0;
    right: 0;
    width: 750px;
    min-width: 750px;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    overflow-y: auto;
}

.button-container {
    display: flex;
    gap: 10px;
}

.button-container button {
    flex: 1;
    white-space: nowrap;
}

/* For small screens */
@media (max-width: 800px) {
    .detail-view {
        width: 100%;
        /* Full width on small screens */
        min-width: unset;
    }

    .button-container {
        flex-direction: column;
        /* Stack buttons vertically */
    }

    .button-container button {
        width: 100%;
        margin-bottom: 10px;
    }
}

button#refreshJobsButton {
    /* Button */
    /* Grays/White */
    background: #ffffff;
    /* M3/Elevation Light/1 */
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    color: #000;
    margin-right: 2em;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: capitalize;

}
